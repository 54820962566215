import { Reducer } from "redux";
import {
  actionTypes,
  defaultApprovalState,
  IApprovalState,
} from "./ApprovalState";
import { FeatureType, OperationType } from "../rule/constants";

const FlagLevelMap = {
  1: "Confirm",
  2: "Probable",
  3: "Possible",
  99: "Monitor",
  4: "Override",
  5: "Clear",
};

const BypassTypeMap = {
  0: "Unknown",
  1: "False Positive",
  2: "Allowed Business",
};

function transformData(input, featureType) {
  const resp: any = [];
  input?.forEach((item) => {
    if (item.featureId !== featureType) return [];
    const requestData = JSON.parse(item.requestData);
    const impactAssessment = JSON.parse(item.requestImpactJson);

    // temp
    // if (impactAssessment === null) {
    //   return;
    // }

    const parsedVNext = impactAssessment?.vNextSamplesData
      ? JSON.parse(impactAssessment.vNextSamplesData)
      : null;

    const supportingInfo =
      Array.isArray(parsedVNext) || parsedVNext === null
        ? { AdEntities: [], AIMAdEntities: [] }
        : parsedVNext;

    const keyWords = impactAssessment?.KeywordsJSON
      ? JSON.parse(impactAssessment.KeywordsJSON)
      : [];

    requestData.rules?.forEach((rule) => {
      resp.push({
        requestId: item.requestId || "N/A",
        operationType: item.operationType,
        term: rule.term || "N/A",
        flagarea: rule.defaultFlagAreaId || "N/A",
        overrideFlagArea: rule.overrideFlagAreaId || "N/A",
        flagLevel: FlagLevelMap[rule?.defaultFlagLevel] || "N/A",
        overrideFlagLevel: FlagLevelMap[rule?.overrideFlagLevel] || "N/A",
        bypassType: BypassTypeMap[rule?.BypassType] || "N/A",
        country: rule.country,
        language: rule.language,
        requestor: item.requestorName || "N/A",
        demandAdScanned:
          impactAssessment?.DemandAdScanned ||
          impactAssessment?.DemandAdScanned === 0
            ? impactAssessment?.DemandAdScanned
            : "N/A",
        additionalAdDemand:
          impactAssessment?.DemandAdImpacted ||
          impactAssessment?.DemandAdImpacted === 0
            ? impactAssessment?.DemandAdImpacted
            : "N/A",
        impactAdPercentage:
          impactAssessment?.ImpactAdPercentage ||
          impactAssessment?.ImpactAdPercentage === 0
            ? impactAssessment?.ImpactAdPercentage
            : "0.000%",
        demandKwdScanned:
          impactAssessment?.DemandKwdScanned ||
          impactAssessment?.DemandKwdScanned === 0
            ? impactAssessment?.DemandKwdScanned
            : "N/A",
        additionalKwdDemand:
          impactAssessment?.DemandKwdImpacted ||
          impactAssessment?.DemandKwdImpacted === 0
            ? impactAssessment?.DemandKwdImpacted
            : "N/A",
        impactKwdPercentage:
          impactAssessment?.ImpactKwdPercentage ||
          impactAssessment?.ImpactKwdPercentage === 0
            ? impactAssessment?.ImpactKwdPercentage
            : "0.000%",
        estRevenue:
          impactAssessment?.EstRevenue || impactAssessment?.EstRevenue === 0
            ? impactAssessment?.EstRevenue
            : "N/A",
        estImpressions:
          impactAssessment?.EstImpressions ||
          impactAssessment?.EstImpressions === 0
            ? impactAssessment?.EstImpressions
            : "N/A",
        sampleDemand:
          item.sampleDemand || item.sampleDemand === 0
            ? item.sampleDemand
            : "N/A",
        overrideType: item.overrideType || "N/A",
        CID: rule.advertiserId || "N/A",
        XIDs: item.XIDs || "N/A",
        comments: rule.comments || "N/A",
        supportingInfo,
        keyWords,
        modifiedAt: item.modifiedAt,
        createdAt: item.createdAt || item.modifiedAt, // fallback to modifiedAt if createdAt is not available
      });
    });
  });

  return resp.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
}

export const ApprovalReducer: Reducer<IApprovalState> = (
  state: IApprovalState = defaultApprovalState,
  action: any
) => {
  const fetchedData = action?.payload || [];

  switch (action.type) {
    case actionTypes.GET_APPROVAL_ROWS:
      return {
        ...state,
        defaultRules: transformData(fetchedData, FeatureType.DefaultTerm),
        flagOverrideRules: transformData(fetchedData, FeatureType.FlagOverride),
        termOverrideRules: transformData(fetchedData, FeatureType.TermOverride),
      };

    case actionTypes.GET_APPROVAL_ROWS_FAILED:
      return defaultApprovalState;

    default:
      return state || {};
  }
};
