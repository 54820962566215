import { Conditions } from "../../components/common/table/table-command-bar/plugins/filters/Filters.types";
import { ITablePresets } from "../../components/common/table/Table.types";
import { LoadState } from "@trinity/enums";

export enum UserPreferencesModule {
  NONE = "NONE",
  ADMIN_USER_MANAGEMENT = "ADMIN-USER-MANAGEMENT",
  APPROVAL_TABLE = "APPROVAL_TABLE",
  REVIEWER_REVIEW_PACKAGE = "REVIEWER-REVIEW-PACKAGE",
  REVIEWER_SUBMITTED_PACKAGES = "REVIEWER-SUBMITTED-PACKAGES",
  ADMIN_QUEUE_MANAGEMENT = "ADMIN-QUEUE-MANAGEMENT",
  ADMIN_PACKAGE_MANAGEMENT = "ADMIN-PACKAGE-MANAGEMENT",
  ADMIN_PACKAGE_MANAGEMENT_SUBMITTED_ONLY = "ADMIN-PACKAGE-MANAGEMENT-SUBMITTED-ONLY",
  TNS_EXPLORE = "TNS-EXPLORE",
  TNS_ENFORCEMENT = "TNS-ENFORCEMENT",
  TNS_DETECTION = "TNS-DETECTION",
  TNS_AIV = "TNS-AIV",
  ESCALATIONS_JOB_STATUS = "ESCALATIONS-JOB-STATUS",
  ESCALATIONS_JOB_REVIEW = "ESCALATIONS-JOB-REVIEW",
  LISTMANAGER_SEEDTOKEN_JOB_STATUS = "LISTMANAGER-SEEDTOKEN-JOB-STATUS",
  MISMATCH_REVIEW_PACKAGE = "MISMATCH-REVIEW-PACKAGE",
  MISMATCH_PACKAGES = "MISMATCH-PACKAGES",
  REJECTION_REVIEW_PACKAGE = "REJECTION-REVIEW-PACKAGE",
  REJECTION_PACKAGES = "REJECTION-PACKAGES",
  QREREVIEWS_REVIEW_PACKAGE = "QREREVIEWS-REVIEW-PACKAGE",
  QREREVIEWS_PACKAGES = "QREREVIEWS-PACKAGES",
  SEARCH_TRADEMARK = "SEARCH-TRADEMARK",
  IMPORT_TRADEMARK = "IMPORT-TRADEMARK",
  SEARCH_QRE = "SEARCH-QRE",
  SEARCH_DSA_BLOCK = "SEARCH-DSA-BLOCK",
  SEARCH_PLA_BLOCK = "SEARCH-PLA-BLOCK",
  SEARCH_PUBLISHER_RULE = "SEARCH-PUBLISHER-RULE",
  SEARCH_ENTITY_LIST = "SEARCH-ENTITY-LIST",
  SEARCH_DEFAULT_RULE = "SEARCH-DEFAULT-RULE",
  SEARCH_TERM_OVERRIDE = "SEARCH-TERM-OVERRIDE",
  SEARCH_FLAG_OVERRIDE = "SEARCH-FLAG-OVERRIDE",
  SEARCH_URL = "SEARCH-URL",
  ENTITY_TRACKER = "ENTITY-TRACKER",
}

export interface ITnsExplorePreferences {
  customer?: IUserModulePreferences;
  account?: IUserModulePreferences;
  stores?: IUserModulePreferences;
  users?: IUserModulePreferences;
  domains?: IUserModulePreferences;
  customerProfileAccounts?: IUserModulePreferences;
  customerProfileDomains?: IUserModulePreferences;
  userWidgets?: IUserModulePreferences;
  storeWidget?: IUserModulePreferences;
  paymentWidgets?: IUserModulePreferences;
  investigateWidget?: IUserModulePreferences;
  networkViewExaminePanel?: IUserModulePreferences;
  insightsExaminePanel?: IUserModulePreferences;
  insightsCardPanel?: IUserModulePreferences;
  extra?: IExtraPreferences;
  customerRiskExaminePanel?: IUserModulePreferences;
  mcaExaminePanel?: IUserModulePreferences;
  decisionHistory?: IUserModulePreferences;
  detectionHistory?: IUserModulePreferences;
  aivData?: IUserModulePreferences;
}

export interface IEntityTrackerPreferences {
  flag?: IUserModulePreferences;
  category?: IUserModulePreferences;
  status?: IUserModulePreferences;
  entityHistory?: IUserModulePreferences;
  flagLevelOverride?: IUserModulePreferences;
  ruleLevelOverride?: IUserModulePreferences;
  CustomerPilotFeature?: IUserModulePreferences;
  EditorialCustomerPilotFeature?: IUserModulePreferences;
}

export interface ITnsEnforcementPreferences {
  decisionHistory?: IUserModulePreferences;
}
export interface ITnsDetectionPreferences {
  detectionHistory?: IUserModulePreferences;
}
export interface ITnsAIVPreferences {
  aivData?: IUserModulePreferences;
}

export interface IUserPreferenceState<T = IUserModulePreferences> {
  // app version
  version: number | undefined;

  // Global app preferences
  app: IUserAppPreference;

  // module specific preferences
  modules: Record<UserPreferencesModule, T>;

  // load state of user preferences
  loadState: LoadState;
}

export interface IExtraPreferences {
  [key: string]: any;
}

export interface IUserModulePreferences {
  tablePresets?: ITablePresets;
  extra?: IExtraPreferences;
}

export interface IUserAppPreference {
  /**
   * Whether to skip the training
   */
  skipTraining: boolean;
}

export const DefaultUserPreferenceState: IUserPreferenceState = {
  version: undefined,
  loadState: LoadState.NotLoaded,
  app: {
    skipTraining: false,
  },
  modules: {
    NONE: {},
    "ADMIN-USER-MANAGEMENT": {},
    APPROVAL_TABLE: {
      tablePresets: {
        paging: {
          currentPage: 1,
          rowsPerPage: 100,
        },
        columns: {
          applied: [],
          saved: [],
          sorted: [],
          modified: [],
        },
      },
    },
    "REVIEWER-REVIEW-PACKAGE": {},
    "REVIEWER-SUBMITTED-PACKAGES": {},
    "ADMIN-QUEUE-MANAGEMENT": {},
    "ADMIN-PACKAGE-MANAGEMENT": {},
    "ADMIN-PACKAGE-MANAGEMENT-SUBMITTED-ONLY": {},
    "TNS-EXPLORE": {} as ITnsExplorePreferences,
    "TNS-ENFORCEMENT": {},
    "TNS-DETECTION": {},
    "TNS-AIV": {},
    "ESCALATIONS-JOB-STATUS": {},
    "ESCALATIONS-JOB-REVIEW": {},
    "LISTMANAGER-SEEDTOKEN-JOB-STATUS": {},
    "MISMATCH-REVIEW-PACKAGE": {},
    "MISMATCH-PACKAGES": {},
    "REJECTION-REVIEW-PACKAGE": {},
    "REJECTION-PACKAGES": {},
    "QREREVIEWS-REVIEW-PACKAGE": {},
    "QREREVIEWS-PACKAGES": {},
    "SEARCH-TRADEMARK": {},
    "IMPORT-TRADEMARK": {},
    "SEARCH-QRE": {},
    "SEARCH-DSA-BLOCK": {},
    "SEARCH-PLA-BLOCK": {},
    "SEARCH-PUBLISHER-RULE": {},
    "SEARCH-ENTITY-LIST": {},
    "SEARCH-DEFAULT-RULE": {},
    "SEARCH-TERM-OVERRIDE": {},
    "SEARCH-FLAG-OVERRIDE": {},
    "SEARCH-URL": {},
    "ENTITY-TRACKER": {},
  },
};
