import {
  AccountInfo,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { configs } from "../../configs";

declare type NullableAccountInfo = AccountInfo | null;

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  public myMSALObj: PublicClientApplication;
  private account: NullableAccountInfo;
  private silentRequest: SilentRequest;
  private redirectRequest: RedirectRequest;
  public loginRedirectRequest: RedirectRequest;
  private profileRedirectRequest: RedirectRequest;
  private silentProfileRequest: SilentRequest;
  private adsRedirectRequest: RedirectRequest;
  private silentAdsRequest: SilentRequest;
  private riskReadRedirectRequest: RedirectRequest;
  private silentRiskReadRequest: SilentRequest;
  private riskWriteRedirectRequest: RedirectRequest;
  private silentRiskWriteRequest: SilentRequest;
  private riskPremiumWriteRedirectRequest: RedirectRequest;
  private silentRiskPremiumWriteRequest: SilentRequest;
  private riskAdminRedirectRequest: RedirectRequest;
  private silentRiskAdminRequest: SilentRequest;

  constructor() {
    this.myMSALObj = new PublicClientApplication({
      auth: {
        clientId: configs.client.msal.appId,
        redirectUri: configs.client.msal.redirectUri,
      },
    });

    this.account = null;

    this.loginRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesUserRead,
    };

    this.profileRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesUserRead,
    };

    this.silentProfileRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesUserRead,
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.adsRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesAdsManage,
    };

    this.silentAdsRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesAdsManage,
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.riskReadRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesRISKRead,
    };

    this.silentRiskReadRequest = {
      authority: configs.client.msal.authority,
      scopes: configs.client.msal.scopesRISKRead,
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.riskWriteRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
      ],
    };

    this.silentRiskWriteRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
      ],
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.riskPremiumWriteRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
        ...configs.client.msal.scopesRISKPremiumWrite,
      ],
    };

    this.silentRiskPremiumWriteRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
        ...configs.client.msal.scopesRISKPremiumWrite,
      ],
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.riskAdminRedirectRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
        ...configs.client.msal.scopesRISKPremiumWrite,
      ],
    };

    this.silentRiskAdminRequest = {
      authority: configs.client.msal.authority,
      scopes: [
        ...configs.client.msal.scopesRISKRead,
        ...configs.client.msal.scopesRISKWrite,
        ...configs.client.msal.scopesRISKPremiumWrite,
      ],
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.silentRequest = {
      authority: configs.client.msal.authority,
      scopes: [configs.client.msal.scopeMISE],
      account: {
        homeAccountId: "",
        environment: "",
        tenantId: "",
        username: "",
        localAccountId: "",
      },
      forceRefresh: false,
    };

    this.redirectRequest = {
      authority: configs.client.msal.authority,
      scopes: [configs.client.msal.scopeMISE],
    };
  }

  getAccount(): NullableAccountInfo {
    const currentAccounts = this.myMSALObj.getAllAccounts();

    if (currentAccounts.length > 0) {
      this.account = currentAccounts[0];
    }

    return this.account;
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.account as AccountInfo,
    };

    this.myMSALObj.logout(logOutRequest);
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string | null> {
    this.silentProfileRequest.account = this.account as AccountInfo;
    return this.getTokenRedirect(
      this.silentProfileRequest,
      this.profileRedirectRequest
    );
  }

  /**
   * Gets the token to read ads data, or falls back to interactive redirect.
   */
  async getAdsTokenRedirect(): Promise<string | null> {
    this.silentAdsRequest.account = this.account as AccountInfo;
    return this.getTokenRedirect(
      this.silentAdsRequest,
      this.adsRedirectRequest
    );
  }

  async getToken() {
    this.silentRequest.account = this.account as AccountInfo;
    return this.getTokenRedirect(this.silentRequest, this.redirectRequest);
  }

  /**
   * Gets the token to check Risk permission, or falls back to interactive redirect.
   */
  async getRiskTokenRedirect(riskScope: string): Promise<string | null> {
    switch (riskScope) {
      case "RISKRead":
        this.silentRiskReadRequest.account = this.account as AccountInfo;
        return this.getTokenRedirect(
          this.silentRiskReadRequest,
          this.riskReadRedirectRequest
        );
      case "RISKWrite":
        this.silentRiskWriteRequest.account = this.account as AccountInfo;
        return this.getTokenRedirect(
          this.silentRiskWriteRequest,
          this.riskWriteRedirectRequest
        );
      case "RISKPremiumWrite":
        this.silentRiskPremiumWriteRequest.account = this
          .account as AccountInfo;
        return this.getTokenRedirect(
          this.silentRiskPremiumWriteRequest,
          this.riskPremiumWriteRedirectRequest
        );
      case "RISKAdmin":
        this.silentRiskAdminRequest.account = this.account as AccountInfo;
        return this.getTokenRedirect(
          this.silentRiskAdminRequest,
          this.riskAdminRedirectRequest
        );
      default:
        this.silentRiskReadRequest.account = this.account as AccountInfo;
        return this.getTokenRedirect(
          this.silentRiskReadRequest,
          this.riskReadRedirectRequest
        );
    }
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      console.log("silent token acquisition fails.");
      //if (!this.myMSALObj.getActiveAccount()) this.login("loginRedirect");
      if (e instanceof InteractionRequiredAuthError) {
        console.log("acquiring token using redirect");
        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
        return null;
      } else {
        console.error(e);
        return null;
      }
    }
  }

  async acquireToken(scope?: string) {
    switch (scope) {
      case "ads":
        return await this.getAdsTokenRedirect();

      case "RISKRead":
      case "RISKWrite":
      case "RISKPremiumWrite":
      case "RISKAdmin":
        return await this.getRiskTokenRedirect(scope);

      case "profile":
        return await this.getProfileTokenRedirect();

      default:
        return await this.getToken();
    }
  }
}

export const authInstance = new AuthModule();
