import { Reducer } from "redux";
import {
  DefaultSearchRule_DIRECT,
  DefaultUpdateRule_DIRECT,
  ISearchRule_DIRECT,
  IUpdateRule_DIRECT,
  actionTypes_DIRECT,
} from "./RuleState";

export const SearchRuleReducer: Reducer<ISearchRule_DIRECT> = (
  state: ISearchRule_DIRECT = DefaultSearchRule_DIRECT,
  action: any
) => {
  const fetchedData = action?.payload?.data || {};
  switch (action.type) {
    case actionTypes_DIRECT.SEARCH_DEFAULT_TERM_RULE:
      return {
        ...state,
        defaultTermRules: fetchedData.defaultTermRules,
      };
    case actionTypes_DIRECT.SEARCH_TERM_OVERRIDE_RULE:
      return {
        ...state,
        termOverrideRule: fetchedData.termOverrideRules,
      };
    case actionTypes_DIRECT.SEARCH_FLAG_OVERRIDE_RULE:
      return {
        ...state,
        flagOverrideRule: fetchedData.flagOverrideRules,
      };
    case actionTypes_DIRECT.SEARCH_URL:
      return {
        ...state,
        urlRule: fetchedData.urlRules,
      };
    default:
      return state || {};
  }
};

export const UpdateRuleReducer: Reducer<IUpdateRule_DIRECT> = (
  state: IUpdateRule_DIRECT = DefaultUpdateRule_DIRECT,
  action: any
) => {
  const fetchedData = action?.payload?.data || {};
  switch (action.type) {
    case actionTypes_DIRECT.UPDATE_DEFAULT_TERM_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    case actionTypes_DIRECT.UPDATE_TERM_OVERRIDE_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    case actionTypes_DIRECT.UPDATE_FLAG_OVERRIDE_RULE:
      return {
        ...state,
        ruleData: fetchedData.rules,
      };
    default:
      return state || {};
  }
};
