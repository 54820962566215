export interface IMsalConfig {
  appId: string;
  redirectUri: string;
  authority: string;
  scopesUserRead: Array<string>;
  scopesAdsManage: Array<string>;
  scopesRISKPremiumWrite: Array<string>;
  scopesRISKWrite: Array<string>;
  scopesRISKRead: Array<string>;
  scopeMISE: string;
}

export interface IEndpointConfig {
  graphEndpoint: string;
  vNextServiceEndpoint: string;
  appServiceEndpoint: string;
  tnsEndpoint: string;
  trinityEditorialEndpoint: string;
}

export interface IClientConfig {
  msal: IMsalConfig;
  endpoint: IEndpointConfig;
  clarityProjectId: string;
}

export const clientConfig: IClientConfig = {
  msal: {
    appId: import.meta.env.VITE_AAD_APP_ID || "",
    redirectUri: import.meta.env.VITE_AAD_REDIRECT_URI || "",
    authority: import.meta.env.VITE_AAD_AUTHORITY || "",
    scopesUserRead: ["user.read", "user.readbasic.all"],
    scopesAdsManage: (import.meta.env.VITE_SCOPE_ADS_MANAGE || "").split(","),
    scopesRISKPremiumWrite: (
      import.meta.env.VITE_RISK_SCOPE_PREMIUM_WRITE || ""
    ).split(","),
    scopesRISKWrite: (import.meta.env.VITE_RISK_SCOPE_WRITE || "").split(","),
    scopesRISKRead: (import.meta.env.VITE_RISK_SCOPE_READ || "").split(","),
    scopeMISE: import.meta.env.VITE_MISE_SCOPE || "",
  },
  endpoint: {
    graphEndpoint: import.meta.env.VITE_GRAPH_ENDPOINT || "",
    vNextServiceEndpoint: import.meta.env.VITE_API_ENDPOINT || "",
    appServiceEndpoint: import.meta.env.VITE_APP_SERVICE_ENDPOINT || "",
    tnsEndpoint: import.meta.env.VITE_TNS_ENDPOINT || "",
    trinityEditorialEndpoint:
      import.meta.env.VITE_TRINITY_EDITORIAL_ENDPOINT || "",
  },
  clarityProjectId: import.meta.env.VITE_TRINITY_CLARITY_PROJECT_ID || "",
};
