import * as adminMismatchReportReducers from "./admin-mismatch-report/MismatchReportReducer";
import { IAdminMismatchReportState } from "./admin-mismatch-report/MismatchReportState";
import * as adminRejectionReportReducers from "./admin-rejection-report/RejectionReportReducer";
import { IAdminRejectionReportState } from "./admin-rejection-report/RejectionReportState";
import * as adminQREReviewsReportReducers from "./admin-qre-reviews-report/QREReviewsReportReducer";
import { IAdminQREReviewsReportState } from "./admin-qre-reviews-report/QREReviewsReportState";
import * as AdminOverviewReducers from "./admin-overview/AdminOverviewReducer";
import { IAdminOverviewState } from "./admin-overview/AdminOverviewState";
import * as AdminQueueManagementReducer from "./admin-queue-management/AdminQueueManagementReducer";
import * as AdminPackageManagementReducer from "./admin-package-management/AdminPackageManagementReducer";
import { IAdminQueueManagementState } from "./admin-queue-management/AdminQueueManagementState";
import * as AdminUserManagementReducer from "./admin-user-management/AdminUserManagementReducer";
import { IAdminUserManagementState } from "./admin-user-management/AdminUserManagementState";
import * as CommonReducer from "./common/CommonReducer";
import { ICommonState } from "./common/CommonState";
import * as EscalationsReducers from "./escalations/EscalationsReducer";
import { IEscalationsState } from "./escalations/EscalationsState";
import * as HeaderReducers from "./header/HeaderReducer";
import { IHeaderState } from "./header/HeaderState";
import * as ListManagerNormalizedTokenCheckReducers from "./listmanager-normalized-token-check/NormalizedTokenCheckReducer";
import { IListManagerNormalizedTokenCheckState } from "./listmanager-normalized-token-check/NormalizedTokenCheckState";
import * as ListManagerSeedTokenReducers from "./listmanager-seed-token/ListManagerSeedTokenReducer";
import { IListManagerSeedTokenState } from "./listmanager-seed-token/ListManagerSeedTokenState";
import * as ReviewPackageReducer from "./review-package/ReviewPackageReducer";
import { IReviewPackageState } from "./review-package/ReviewPackageState";
import * as ReviewerOverviewReducers from "./reviewer-overview/ReviewerOverviewReducer";
import { IReviewerOverviewState } from "./reviewer-overview/ReviewerOverviewState";
import * as ReviewerReviewPackageReducers from "./reviewer-review-page/ReviewerReviewPackageReducer";
import { IReviewerReviewPackageState } from "./reviewer-review-page/ReviewerReviewPackageState";
import * as reviewerSubmittedPackagesReducers from "./reviewer-submitted-packages/ReviewerSubmittedPackagesReducer";
import { IReviewerSubmittedPackagesState } from "./reviewer-submitted-packages/ReviewerSubmittedPackagesState";
import * as TNSExploreReducers from "./tns-explore/TNSExploreReducer";
import * as TNSEnforcementReducers from "./tns-enforcement/TNSEnforcementReducer";
import * as TNSEnforcementHistoryReducer from "./tns-enforcement-history/TNSEnforcementHistoryReducer";
import * as TNSDetectionHistoryReducer from "./tns-detection-history/TNSDetectionHistoryReducer";
import * as TNSAIVDataReducer from "./tns-aiv-data/TNSAIVDataReducer";
import { ITNSExploreState } from "./tns-explore/TNSExploreState";
import * as trademarkReducers from "./trademark/TrademarkReducer";
import * as qreReducers from "./qre/QreReducer";
import * as dsaBlockReducers from "./dsa-block/DsaBlockReducer";
import * as plaBlockReducers from "./pla-block/PlaBlockReducer";
import * as publisherRuleReducers from "./publisher-rule/PublisherRuleReducer";
import * as ruleReducers from "./rule/RuleReducer";
import * as ruleReducers_direct from "./rule_direct/RuleReducer";
import * as approvalReducers from "./approval/ApprovalReducer";
import * as EntityTrackerReducer from "./entity-tracker/EntityTrackerReducer";
import {
  IAccountDetails,
  IAdvertiserDetails,
  IRevenueGrainDetails,
  ISearchTrademarkDetails,
  ITrademarkLanguageCountry,
  IUpdateTrademarkState,
  ImportDataWithRevenues,
} from "./trademark/TrademarkState";
import * as UserReducers from "./user/UserReducer";
import { IUserState } from "./user/UserState";
import * as userPackageReducers from "./user-package/UserPackageReducer";
import { IUserPackage } from "./user-package/UserPackageState";
import * as UserPreferenceReducers from "./user-preference/UserPreferenceReducer";
import { IUserPreferenceState } from "./user-preference/UserPreferenceState";
import { IAdminPackageManagementState } from "./admin-package-management/AdminPackageManagementState";
import {
  IQreCapabilityDetail,
  ISearchQreDetails,
  IUpdateQreState,
} from "./qre/QreState";
import { ISearchDsaBlockDetails } from "./dsa-block/DsaBlockState";
import { SearchDsaBlockReducer } from "./dsa-block/DsaBlockReducer";
import { ISearchPlaBlockDetails } from "./pla-block/PlaBlockState";
import { SearchPlaBlockReducer } from "./pla-block/PlaBlockReducer";
import { ITNSEnforcementState } from "./tns-enforcement/TNSEnforcementState";
import { ITNSEnforcementHistoryState } from "./tns-enforcement-history/TNSEnforcementHistoryState";
import { ITNSDetectionHistoryState } from "./tns-detection-history/TNSDetectionHistoryState";
import { ITNSAIVDataState } from "./tns-aiv-data/TNSAIVDataState";
import {
  IPublisherCategory,
  IPublisherCommonData,
  IPublisherRuleDetail,
  ISearchPublisherRuleDetails,
  IUpdatePublisherRuleState,
} from "./publisher-rule/PublisherRuleState";
import { IUpdatePublisherRuleData } from "../modules/publisher-rule/PublisherRule.types";
import { ISearchRule, IUpdateRule } from "./rule/RuleState";
import {
  ISearchRule_DIRECT,
  IUpdateRule_DIRECT,
} from "./rule_direct/RuleState";
import { IEntityTrackerResponse } from "./entity-tracker/EntityTrackerState";
import { IWordBreakerState } from "./word-breaker/WordBreakerState";
import * as WordBreakerReducers from "./word-breaker/WordBreakerReducer";
import { IApprovalState } from "./approval/ApprovalState";

// The top-level state object
export interface IApplicationState {
  header: IHeaderState;
  user: IUserState;
  userPreference: IUserPreferenceState;
  reviewerOverview: IReviewerOverviewState;
  reviewerReviewPackage: IReviewerReviewPackageState;
  reviewerSubmittedPackages: IReviewerSubmittedPackagesState;
  adminOverview: IAdminOverviewState;
  adminUserManagement: IAdminUserManagementState;
  common: ICommonState;
  adminQueueManagement: IAdminQueueManagementState;
  adminPackageManagement: IAdminPackageManagementState;
  router: any;
  userPackage: IUserPackage;
  tnsExplore: ITNSExploreState;
  tnsEnforcement: ITNSEnforcementState;
  tnsEnforcementHistory: ITNSEnforcementHistoryState;
  tnsDetectionHistory: ITNSDetectionHistoryState;
  tnsAIVData: ITNSAIVDataState;
  escalations: IEscalationsState;
  reviewPackage: IReviewPackageState;
  listManagerSeedToken: IListManagerSeedTokenState;
  listManagerNormalizedTokenCheck: IListManagerNormalizedTokenCheckState;
  wordBreaker: IWordBreakerState;
  adminMismatchReport: IAdminMismatchReportState;
  adminRejectionReport: IAdminRejectionReportState;
  adminQREReviewsReport: IAdminQREReviewsReportState;
  //trademark: ITrademarkState;
  advertiserDetails: IAdvertiserDetails;
  accountDetails: IAccountDetails;
  searchTrademark: ISearchTrademarkDetails;
  updateTrademark: IUpdateTrademarkState;
  revenueGrainTrademark: IRevenueGrainDetails;
  importDataWithRevenues: ImportDataWithRevenues;
  trademarkCountryLangMapping: ITrademarkLanguageCountry;
  searchQre: ISearchQreDetails;
  updateQre: IUpdateQreState;
  qreCapability: IQreCapabilityDetail;
  searchDsaBlock: ISearchDsaBlockDetails;
  searchPlaBlock: ISearchPlaBlockDetails;
  searchPublisherRule: ISearchPublisherRuleDetails;
  publisherRule: IPublisherRuleDetail;
  publisherCategories: IPublisherCommonData;
  updatePublisherRule: IUpdatePublisherRuleData;
  searchRule: ISearchRule;
  updateRule: IUpdateRule;
  searchRule_direct: ISearchRule_DIRECT;
  updateRule_direct: IUpdateRule_DIRECT;
  entityTracker: IEntityTrackerResponse;
  approval: IApprovalState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  header: HeaderReducers.headerReducer,
  user: UserReducers.userReducer,
  userPreference: UserPreferenceReducers.userPreferenceReducer,
  reviewerOverview: ReviewerOverviewReducers.reviewerOverviewReducer,
  reviewerReviewPackage:
    ReviewerReviewPackageReducers.reviewerReviewPackageReducer,
  reviewerSubmittedPackages:
    reviewerSubmittedPackagesReducers.reviewerSubmittedPackagesReducer,
  adminOverview: AdminOverviewReducers.adminOverviewReducer,
  adminUserManagement: AdminUserManagementReducer.adminUserManagementReducer,
  common: CommonReducer.commonReducer,
  adminQueueManagement: AdminQueueManagementReducer.adminQueueManagementReducer,
  adminPackageManagement:
    AdminPackageManagementReducer.adminPackageManagementReducer,
  userPackage: userPackageReducers.userPackageReducers,
  tnsExplore: TNSExploreReducers.tnsExploreReducer,
  tnsEnforcement: TNSEnforcementReducers.tnsEnforcementReducer,
  tnsEnforcementHistory:
    TNSEnforcementHistoryReducer.tnsEnforcmentHistoryReducer,
  tnsDetectionHistory: TNSDetectionHistoryReducer.tnsDetectionHistoryReducer,
  tnsAIVData: TNSAIVDataReducer.tnsAIVDataReducer,
  escalations: EscalationsReducers.escalationsReducer,
  reviewPackage: ReviewPackageReducer.reviewPackageReducer,
  listManagerSeedToken:
    ListManagerSeedTokenReducers.listManagerSeedTokenReducer,
  listManagerNormalizedTokenCheck:
    ListManagerNormalizedTokenCheckReducers.listManageNormalizedTokenCheckReducer,
  wordBreaker: WordBreakerReducers.wordBreakerReducer,
  adminMismatchReport: adminMismatchReportReducers.adminMismatchReportReducer,
  adminRejectionReport:
    adminRejectionReportReducers.adminRejectionReportReducer,
  adminQREReviewsReport:
    adminQREReviewsReportReducers.adminQREReviewsReportReducer,
  //trademark: trademarkReducers.TrademarkReducer,
  advertiserDetails: trademarkReducers.AdvertiserReducer,
  accountDetails: trademarkReducers.AccountReducer,
  importDataWithRevenues: trademarkReducers.ImportTrademarkReducer,
  searchTrademark: trademarkReducers.SearchTrademarkReducer,
  updateTrademark: trademarkReducers.UpdateTrademarkReducer,
  revenueGrainTrademark: trademarkReducers.RevenueGrainReducer,
  trademarkCountryLangMapping: trademarkReducers.FetchCountryLanguageReducer,
  searchQre: qreReducers.SearchQreReducer,
  updateQre: qreReducers.UpdateQreReducer,
  qreCapability: qreReducers.QreCapabilityReducer,
  searchDsaBlock: dsaBlockReducers.SearchDsaBlockReducer,
  searchPlaBlock: plaBlockReducers.SearchPlaBlockReducer,
  searchPublisherRule: publisherRuleReducers.SearchPublisherRuleReducer,
  publisherCategories: publisherRuleReducers.PublisherCategoryReducer,
  updatePublisherRule: publisherRuleReducers.UpdatePublisherRuleReducer,
  searchRule: ruleReducers.SearchRuleReducer,
  updateRule: ruleReducers.UpdateRuleReducer,
  searchRule_direct: ruleReducers_direct.SearchRuleReducer,
  updateRule_direct: ruleReducers_direct.UpdateRuleReducer,
  entityTracker: EntityTrackerReducer.entityTrackerReducer,
  approval: approvalReducers.ApprovalReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface IAppThunkAction<TAction> {
  (dispatch: (action: TAction) => any, getState: () => IApplicationState): void;
}
