import {
  ICommandBarProps,
  IconButton,
  IContextualMenuItem,
  IIconProps,
  Persona,
  PersonaSize,
  Stack,
  Text,
  PersonaPresence,
} from "@fluentui/react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { configs } from "../../configs";
import { getStyles } from "./Header.styles";
import { actionCreators as headerActions } from "../../store/header/HeaderActions";
import { HeaderItems } from "@trinity/enums";
import { IApplicationState } from "../../store";

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const state = useSelector((state: IApplicationState) => ({
    ...state.user,
    ...state.header,
  }));

  const onClickAppName = React.useCallback(() => {
    navigate("/");
    dispatch(headerActions.setWorkspace(""));
  }, [dispatch, navigate]);

  const onClickWorkspaceSwitch = React.useCallback(() => {
    dispatch(headerActions.openWorkspaces());
  }, [dispatch]);

  const onRenderWorkspaceName = React.useCallback(() => {
    const selectedWorkspace = configs.workspace.find(
      (e) => e.key === state.selectedWorkspaceKey
    );
    return (
      <Stack horizontal>
        <Text className="workspace-name">
          {selectedWorkspace && selectedWorkspace.name}
        </Text>
      </Stack>
    );
  }, [state]);

  /*const onRenderSearch = React.useCallback((item: IContextualMenuItem) => {
    return <SearchControl />;
  }, []);*/

  const onRenderItem = React.useCallback(
    (item: IContextualMenuItem) => {
      const styles = getStyles();
      if (item.type === "icon") {
        const icon: IIconProps = { iconName: item.iconName };
        return (
          <IconButton
            iconProps={icon}
            styles={
              state.selectedItemKey === HeaderItems[item.key]
                ? styles.headerItemSelected
                : styles.headerItem
            }
            ariaLabel={item.title}
            title={item.title}
            onClick={() =>
              dispatch(
                headerActions.openHeaderItem(HeaderItems[item.key], item.title)
              )
            }
          />
        );
      }

      if (item.type === "persona") {
        return (
          <IconButton
            styles={
              state.selectedItemKey === HeaderItems[item.key]
                ? styles.headerItemSelected
                : styles.headerItem
            }
            ariaLabel={item.title}
            title={item.title}
            onClick={() =>
              dispatch(
                headerActions.openHeaderItem(HeaderItems[item.key], item.title)
              )
            }
            role="menuitem"
          >
            <Persona
              styles={styles.personaSmall}
              size={PersonaSize.size28}
              imageInitials={state.imageInitials}
              imageUrl={state.imageUrl}
              showInitialsUntilImageLoads={true}
            />
          </IconButton>
        );
      }
    },
    [dispatch, state]
  );

  const commandBarProps: ICommandBarProps = React.useMemo(
    () => ({
      items: [
        {
          key: "WorkspaceSwitcher",
          iconProps: {
            iconName: "Waffle",
          },
          iconOnly: true,
          className: "workspace-switcher",
          disabled: false,
          title: t("header.workspace-launch-title"),
          onClick: (ev) => onClickWorkspaceSwitch(),
        },
        {
          key: "AppName",
          iconOnly: false,
          className: "app-name",
          name: t("header.app-title"),
          disabled: false,
          onClick: (ev) => onClickAppName(),
        },
        {
          key: "WorkspaceName",
          iconOnly: false,
          className: "workspace-name",
          name: "",
          disabled: true,
          onRender: (item) => onRenderWorkspaceName(),
        },
        /*{
        key: "Search",
        iconOnly: false,
        className: "workspace-name",
        name: "",
        disabled: true,
        onRender: item => onRenderSearch(item)
      }*/
      ],
      farItems: [
        /*{
					key: "Notification",
					type: "icon",
					title: t("header.notifications-title"),
					iconName: "Ringer",
					iconOnly: false,
					disabled: false,
					onRender: (item) => onRenderItem(item),
				},
				{
					key: "Help",
					type: "icon",
					title: t("header.help-title"),
					iconName: "Articles",
					iconOnly: false,
					disabled: false,
					onRender: (item) => onRenderItem(item),
				},*/
        {
          key: "Persona",
          type: "persona",
          title: t("header.myaccount-title"),
          iconOnly: false,
          disabled: false,
          onRender: (item) => onRenderItem(item),
        },
      ],
    }),
    [
      t,
      onClickAppName,
      onClickWorkspaceSwitch,
      onRenderItem,
      onRenderWorkspaceName,
    ]
  );

  React.useEffect(() => {
    const locationParts = location.pathname.split("/");

    if (locationParts.length > 1) {
      const selectedWorkspace = configs.workspace.find(
        (e) => e.key === locationParts[1].toLowerCase()
      );
      dispatch(
        headerActions.setWorkspace(
          (selectedWorkspace && selectedWorkspace.key) || ""
        )
      );
    }
  }, [dispatch, navigate]);

  return (
    <CommandBar
      data-test="commandBar"
      styles={getStyles().commandBar}
      {...{ ...commandBarProps }}
    />
  );
}

export default Header;
