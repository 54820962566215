import { authInstance } from "../components/auth/AuthModule";
import { clientConfig } from "../configs/ClientConfig";

/**
 * LoggerService
 */
export class LoggerService {
  /**
   * Endpoint for logger service
   */
  private static _loggerServiceEndpoint =
    clientConfig.endpoint.appServiceEndpoint + "logs";

  /**
   * Posts/logs an error message
   *
   * trackingGuid string Tracking GUID
   * featureName string Feature or module name where error was thrown
   * message string Mesage to log
   **/
  public static async logError(
    trackingGuid: string,
    featureName: string,
    message: string,
    userAlias: string
  ) {
    const accessToken = await authInstance.acquireToken();

    fetch(this._loggerServiceEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "User-Alias": userAlias,
      },
      body: JSON.stringify([
        {
          trackingGuid: trackingGuid,
          featureName: featureName,
          message: message,
        },
      ]),
    });
  }
}
